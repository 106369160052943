import React, { useEffect } from "react"
import { Link } from "gatsby"
import {
  BlogArticleImage,
  BlogArticleText,
  BlogArticleSubtitle,
  BlogArticleListWrapper,
  BlogArticleListItem,
  Callout,
  Grid5TextWithLeftBlackLine,
  GridBlackTitle,
  GridBox,
  GridContainer,
  GridSeparator,
  GridSpacer,
  Grid5Features2,
  Grid5Testimonials,
  Grid5TinyCallout,
  Standalone,
  Title,
} from "@thedgbrt/logology-ui-components"
import SEO from "../../components/seo"
import { trackSegmentEvent } from "../../helpers/segment"
import { trackAmplitude } from "../../helpers/amplitude"

const CaseStudiesPage = ({ data }) => {
  useEffect(() => {
    trackSegmentEvent("track", "View case studies page")
    trackAmplitude("View case studies page")
  }, [])

  // console.log(data)
  // const post = data.prismicCaseStudies.data.body
  // const renderSlice = (data, index) => {
  //   const { slice_type } = data

  //   if (slice_type === "body_text") {
  //     return (
  //       <>
  //         ddd
  //         <BlogArticleText
  //           html={data.primary.text.html}
  //           title={data.primary.optional_title.text}
  //           key={index}
  //         />
  //       </>
  //     )
  //   } else if (slice_type === "secondary_title") {
  //     return (
  //       <BlogArticleSubtitle
  //         title={data.primary.secondary_title[0].text}
  //         key={index}
  //       />
  //     )
  //   } else if (slice_type === "list") {
  //     return (
  //       <BlogArticleListWrapper key={index}>
  //         {data.items.map((el, index) => (
  //           <BlogArticleListItem html={el.list_item.html} key={index} />
  //         ))}
  //       </BlogArticleListWrapper>
  //     )
  //   } else if (slice_type === "image" || slice_type === "image_with_legend") {
  //     const { dimensions } = data.primary.image
  //     return (
  //       <BlogArticleImage
  //         alt={data.primary.image.alt}
  //         legend={
  //           slice_type === "image_with_legend" ? data.primary.legend.html : ""
  //         }
  //         src={data.primary.image.url}
  //         height={dimensions ? dimensions.height : ""}
  //         width={dimensions ? dimensions.width : ""}
  //         key={index}
  //       />
  //     )
  //   }
  // }

  return (
    <>
      <SEO title="Logo design case studies" />

      {/* {post.map((slice, index) => renderSlice(slice, index))} */}

      <GridBlackTitle
        gridType={5}
        colSpans={[[1, 6], [1, 6], [3, 10]]}
        white
        withBorder
        hideBorderLeft={[true, false, false]}
      >
        An evolving brand design service
      </GridBlackTitle>

      <GridBlackTitle gridType={5} colSpans={[[3, 8], [3, 8], [5, 12]]}>
        Ready to use or to be customized
      </GridBlackTitle>

      <Grid5TextWithLeftBlackLine description="Have you found a logo you love in our catalog but want it tailored to your business? Or perhaps you purchased a logo from us previously and now aim to achieve a new business milestone, requiring a brand evolution. In either scenario, our Establish package offers an affordable and swift solution to obtain an effective logo design, with full ownership rights and the option to trademark it." />

      <BlogArticleText
        html={
          "<p><strong>1 - Choose your favorite logo from our extensive collection.</strong><br/>After a brief questionnaire, Logology selects logos that resonate with your brand's identity. Our website provides insights on colors, fonts, and symbols, empowering you to make an informed choice.</p> <p><strong>2 - Schedule a personalized interview with the designer.</strong><br/>Let's discuss! Once you're ready, we'll arrange a call to understand your specific needs better.</p> <p><strong>3 - Select from original design variations.</strong><br/>Based on your feedback, I, the designer, will create new variations of the chosen logo concept to suit your requirements.</p> <p><strong>4 - Receive your brand-new, perfected logo straight to your inbox.</strong><br/>Choose your preferred design option from the proposals, and I'll deliver all source files along with an ownership agreement to you.</p>"
        }
      />

      <Grid5TinyCallout colSpans={[[2, 6], [2, 6], [3, 11]]}>
        Some examples from our past customers:
      </Grid5TinyCallout>

      <BlogArticleImage
        alt={"Modos brand logo design before & after"}
        src={"/images/case-studies/before_after_modos.png"}
        height={470}
        width={1600}
      />
      <BlogArticleImage
        alt={"Real AG Carbon brand logo design before & after"}
        src={"/images/case-studies/before_after_realagcarbon.png"}
        height={470}
        width={1600}
      />
      <BlogArticleImage
        alt={"Women founders club brand logo design before & after"}
        src={"/images/case-studies/before_after_wfc.png"}
        height={470}
        width={1600}
      />

      <GridSpacer desktop={58} mobile={40} tablet={40} type={5} />

      <Grid5Testimonials
        testimonial1Desc="If you want it done right, done efficiently and have a very pleasant experience, then Logology is the one for you."
        testimonial1Author="<p>Jim Shelton, VP, Strategy at <a href='https://realagcarbon.com/' target='_blank'>RealAgCarbon</a></p>"
        testimonial1ImgSrc="/images/case-studies/testimonial-jim-shelton-grey.png"
        testimonial1Color="#949494"
        testimonial2Desc="Lucie’s design is simple, elegant, and tells the story of who I am, what my work is about, and who it's for. Spectacular work."
        testimonial2Author="<p>Arvid Kahl, <a href='https://thebootstrappedfounder.com/' target='_blank'>The Bootstrapped Founder</a></p>"
        testimonial2ImgSrc="/images/case-studies/testimonial-arvid-kahl-grey.png"
        testimonial2Color="#949494"
      />

      <GridSpacer desktop={126} mobile={95} tablet={95} type={5} />

      <GridBlackTitle gridType={5} colSpans={[[3, 8], [3, 8], [4, 11]]}>
        A designer devoted to your brand
      </GridBlackTitle>

      <GridSpacer desktop={58} mobile={40} tablet={40} type={5} />

      <Grid5Features2
        descriptions={[
          "I'm Lucie Baratte, the imaginative mind driving Logology. With almost 20 years in graphic design, I've cultivated a deep-seated passion for typography and logo design. Throughout my journey, I've had the privilege to collaborate on a multitude of branding projects, breathing life into the visual identities of both up-and-coming startups and renowned brands.",
          "Every logo in our catalog has been meticulously crafted by me, a professional designer. I've curated color and font combinations so that every proposal suggested by Logology’s website is both relevant and aesthetic. I draw my inspiration from nourishing discussions with project leaders. My work method is based on a semiotic approach and design thinking processes.",
        ]}
        titles={["Who I am", "How I work"]}
        imgSrcs={[
          "/images/case-studies/image-lucie.jpg",
          "/images/case-studies/image-drawing-bts.jpg",
        ]}
      />

      <GridSpacer desktop={126} mobile={95} tablet={95} type={5} />

      <GridBlackTitle gridType={5} colSpans={[[3, 8], [3, 8], [4, 11]]}>
        More than 15 years of logo design
      </GridBlackTitle>

      <BlogArticleImage
        alt={"Bespoke logo & brand design by logology"}
        src={"/images/case-studies/bespoke_logo_brand_design.png"}
        height={537}
        width={1600}
      />

      <Grid5TinyCallout colSpans={[[2, 6], [2, 6], [3, 11]]}>
        Explore further <Link to="/blog">case studies on our blog</Link>
      </Grid5TinyCallout>

      <GridSpacer desktop={126} mobile={95} tablet={95} type={5} />

      <GridBox type={5} colSpans={[[2, 7], [2, 7], [3, 8]]} withBgContainer>
        <Title level={3} standalone>
          What I’ve learned as a brand designer
        </Title>
      </GridBox>

      <GridContainer
        type={5}
        rowsDesktop={["auto"]}
        rowsMobile={["auto"]}
        rowsTablet={["auto"]}
      >
        <GridBox type={5} colSpans={[[3, 8], [3, 8], [4, 10]]}>
          <Standalone>
            <p>
              <em>
                Acquiring a logo is a critical step for every project,
                regardless of its size. The difference between starting with a
                great logo versus merely a decent one can often determine the
                success or failure of making a first impression on clients and
                investors. While a logo may be the smallest representative image
                of a company or institution, it serves as the proudest
                ambassador of the brand, ensuring stability in connection with
                the audience. It's a foundational cornerstone of your marketing
                strategy that should not be left to chance.
              </em>
            </p>
          </Standalone>
        </GridBox>
      </GridContainer>

      <GridSpacer desktop={126} mobile={95} tablet={95} type={5} />

      <GridSeparator />
      <GridContainer type={5}>
        <Callout
          colSpans={[[2, 8], [2, 8], [4, 11]]}
          buttonHref="/questionnaire/"
          buttonText="see the logos that match my values →"
          title="Inspiring brand design for your startup, ready to use or to be customized."
          type={5}
        />
      </GridContainer>
      <GridSeparator />
    </>
  )
}

export const query = graphql`
  query {
    prismicCaseStudies {
      data {
        body {
          primary {
            text {
              text
              type
            }
            secondary_title {
              type
              text
              direction
            }
            optional_title {
              text
            }
          }
          slice_type
        }
      }
    }
  }
`

export default CaseStudiesPage
